<template>
  <v-container
  id="regular-tables"
  fluid
  tag="section"
  >



  <base-material-card

  color="success"
  icon="mdi-clipboard-plus"
  v-if = "usertype == 'admin'"
  title="Add New Item"
  class="px-5 py-3"
  >
  
  <v-container class="py-0">
    <v-row>



      <v-col
      cols="12"
      md="6"
      >
      <v-text-field
      label="Product Name"
      class="purple-input"
      v-model="Finalitem.ItemName"
      />
    </v-col>

    <v-col
    cols="12"
    md="6"
    >
    <v-text-field
    label="Price"
    class="purple-input"
    disabled
    v-model="Finalitem.Price"
    />
  </v-col>

  <v-col
  cols="12"
  md="6"
  >
  <v-select
  v-model="Finalitem.Distribution"
  :items="Partner"
  item-text="D_Name"
  item-value="id"
  return-object
  label="Brand"
  outlined
  dense
  ></v-select>
</v-col>
<v-col
cols="12"
sm="6"
>

<v-select
v-model="Finalitem.type"
:items="Type"
item-text="C_Name"
item-value="id"
return-object
label="Main Type"
outlined
dense
></v-select>
</v-col>
<v-col
cols="12"
md="6"
>
<v-checkbox
v-model="Finalitem.IsActive"
label="Active"
></v-checkbox>
</v-col>

<v-col
cols="12"
md="12"
>

<v-textarea
v-model="Finalitem.Description"
>
<template v-slot:label>
  <div>
    Description 
  </div>
</template>
</v-textarea>

</v-col>
<v-col
cols="12"
md="12"
>

  <v-file-input
    label="Upload PDF"
    v-model="MainPDF"
    outlined
    accept="application/pdf"
    dense
  ></v-file-input>

</v-col>
<v-col
cols="12"
md="12"
>
<div>
  <div 
  v-for = "(imgs , index) in form.Images"
  :key="`t-${imgs}`"
  style="display: inline-block;">

  <v-icon  @click="ondelete(index)"> mdi-close</v-icon>
  <img 
  class="img" 
  :src="imgs"
  width="200" 
  height="200">
</div>

</div>
<div class="control" style="text-align: left;">
 <v-btn
 color="blue-grey"
 class="ma-2 white--text"
 @click="onPickFile"
 >Add New Image
 <v-icon right dark>mdi-cloud-upload</v-icon>


</v-btn>

<input 
type="file" 
style="display: none" 
ref="fileinput" 
accept="image/*"
@change="onfilepicked">
</div>



</v-col>

</v-row>
<template>
  <v-btn
  color="success"
  class="mr-0"
  @click="InsertItemToDB"
  >
  Add Product
</v-btn>
</template>
</v-container>
</base-material-card>


<base-material-card
icon="mdi-clipboard-text"
title="Items View"
class="px-5 py-3"
>
<v-data-table
:headers="headers"
:items="Products"
sort-by="calories"
class="elevation-1"
:search="search"
>

<template v-slot:item.calories="{ item }">
      <v-chip
        :color="getColor(item.IsActive)"
        dark
      >
        {{ item.IsActive }}
      </v-chip>
</template>

<template v-slot:item.type="{ item}">
   <label :id="item.type + Products.indexOf(item)">   {{getcategoryName(item.type , Products.indexOf(item))}}</label>
</template>


<template v-slot:top>
  <v-toolbar
  flat
  >
  <v-toolbar-title>Products List</v-toolbar-title>
  <v-divider
  class="mx-4"
  inset
  vertical
  ></v-divider>
  <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4">
            
          </v-text-field>

  <v-dialog
  v-model="dialog"
  max-width="500px"
  >
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col
          cols="12"
          sm="12"
          md="12"
          >
          <v-text-field
          v-model="editedItem.ItemName"
          label="Product name"
          outlined
          dense
          ></v-text-field>
        </v-col>
       

      <v-col
      cols="12"
      sm="6"
      md="6"
      >
      <v-select
      v-model="editedItem.type"
      :items="Type"
      item-text="C_Name"
      item-value="id"
      label="Type"
      :return-object="false"
      outlined
      dense
      ></v-select>
    </v-col>

    <v-col
    cols="12"
    md="6"
    >
    <v-select
    v-model="editedItem.Distribution"
    :items="Partner"
    item-text="D_Name"
    item-value="D_Name"
    return-object
    label="Distribution"
    outlined
    dense
    ></v-select>
  </v-col>

<v-col
cols="12"
md="4"
>
<center>
  <v-checkbox
  dense
  outlined
  v-model="editedItem.IsActive"
  label="Active"
  ></v-checkbox>

</center>
</v-col>

<v-col
cols="12"
sm="12"
md="12"
>
 <v-file-input
    label="Upload PDF"
    v-model="EditPDF"
    outlined
    accept="application/pdf"
    dense
  ></v-file-input>
</v-col>
<v-col
cols="12"
sm="12"
md="12"
>
<v-textarea
v-model="editedItem.Description"
label="Description"
outlined
dense
>
<template v-slot:label>
  <div>
    Description 
  </div>
</template>
</v-textarea>

<v-col
cols="12"
sm="12"
md="12"
>
<div class='item' >
  <v-row>
  <v-card v-for="(n , index) in editedItem.Images" v-bind:key="index" style="margin: 5px;">
<v-img
  max-height="200"
  max-width="150"
  :src="n"
></v-img>
<v-icon
small
@click="deleteImageItem(editedItem , index)"
>
mdi-delete
</v-icon>
</v-card>
  <center>

          <v-btn
        outlined
        @click="onEditPickFile"
      >
    
      <v-icon
      width="200"
      height="150"
      large>
        mdi-upload
    </v-icon>

</v-btn>
</center>
</v-row>
</div>






</v-col>
</v-col>
</v-row>
</v-container>
<input 
type="file" 
style="display: none" 
ref="fileinput2" 
accept="image/*"
@change="onEditfilepicked($event,editedItem)">

</div>
</v-card-text>

<v-card-actions>
  <v-spacer></v-spacer>
  <v-btn
  color="blue darken-1"
  text
  @click="close"
  >
  Cancel
</v-btn>
<v-btn
color="blue darken-1"
text
@click="save"
>
Save
</v-btn>
</v-card-actions>
</v-card>
</v-dialog>
<v-dialog v-model="dialogDelete" max-width="500px">
  <v-card>
    <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</v-dialog>
</v-toolbar>
</template>
<template v-slot:item.actions="{ item }">
  <v-icon
  small
  class="mr-2"
  @click="editItem(item)"
  >
  mdi-pencil
</v-icon>
<v-icon
small
@click="deleteItem(item)"
>
mdi-delete
</v-icon>
</template>
<template v-slot:no-data>
  <v-btn
  color="primary"
  @click="initialize"
  >
  Reset
</v-btn>
</template>
</v-data-table>
</base-material-card>


<div class="py-3" />

<v-snackbar
      v-model="form.snackbar"
      :multi-line="multiLine"
    >
      {{ form.snakMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="form.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</v-container>

</template>

<script>

  import { db ,fb } from '@/db'
  import { mapMutations, mapState } from 'vuex'

  export default {
    data () {
      return {

        ItemDetails : [],
        collapseDuration: 0,
        docRefrance : '',
        fields : [
        { key: 'ItemName', _style:'min-width:100px' },
        { key: 'Cost', _style:'min-width:100px;' },
        { key: 'SalePrice', _style:'min-width:100px;' },
        { key: 'type', _style:'min-width:100px;'},
        { 
          key: 'show_details', 
          label: '', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false
        }
        ],
        headers: [
        {
          text: 'Product Name',
          align: 'start',
          sortable: false,
          value: 'ItemName',
        },
        { text: 'Distribution', value: 'Distribution.D_Name' },
        { text: 'IsActive', value: 'IsActive' },
        { text: 'type', value: 'type' },
        { text: 'Actions', value: 'actions', sortable: false },
        ],
        selected: [],
        MainItem: [],
        MainPDF : '',
        EditPDF : '',
        components: [],

        form : {
          Images : [] ,
          snackbar : false,
          snakMsg : "Uploaded success"
        },
        Finalitem :{
          Price : 0.0,
          type :'',
          SalePrice: 0.0,
          ItemName : '',
          IsActive : '',
          D_ID : '',
        },
        Type: [],
        ImageFire: [],
        dialog: false,
        dialogDelete: false,
        search: '',
        calories: '',
        desserts: [],
        editedIndex: -1,
        editedItem: {
          ItemName: '',
          ItemCost: 0,
          ItemUnit: 0,
          Is_Decoration: 0,
          AdditionalCost: 0,
        },
        defaultItem: {
          ItemName: '',
          ItemCost: 0,
          ItemUnit: 'g',
          ItemQTY : 0,
          Is_Decoration: 0,
          AdditionalCost: 0,
        },
      }
    },
    computed: {

      Partner(){
        return this.$store.state.AllDistribution
      },
      usertype(){
        return this.$store.state.userType
      },
      Products(){
        return this.$store.state.Product
      },
      headersSec () {
        return [
        {
          text: 'ItemName ',
          align: 'start',
          value: 'ItemName',
        },
        {
          text: 'Cost',
          value: 'ItemCost',
          filter: value => {
            if (!this.calories) return true

              return value < parseInt(this.calories)
          },
        },
        { text: 'Unit', value: 'ItemUnit' },
        { text: 'Is Decoration', value: 'Is_Decoration',  align: 'center' },
        { text: 'Additional Cost', value: 'AdditionalCost' ,  align: 'center' },
        ]
      },
      formTitle () {
        return 'Products' 
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    methods: {
      toggleDetails (item , index) {
        this.$set(this.MainItem[index], '_toggled', !item._toggled)
        this.collapseDuration = 300
        this.$nextTick(() => { this.collapseDuration = 0})
      },
      getimage : async function(item , index) {
        await db.collection('Items')
        .doc('MainItem')
        .collection('Material')
        .doc(item.id)
        .get()
        .then(async doc => {
          let x =  await {Link : doc.data().Images[0]};
          document.getElementById(index).src = x.Link

        })
      },
      getitemDeatails : async function(item , index){
        let x = await db
        .collection('Items')
        .doc('MainItem')
        .collection('Material')
        .doc(item.id)
        .get()
        .then( snapshots => {
          return {...snapshots.data().components}
        })

        return x
      },
       getColor (IsActive) {
        
        if (IsActive) return 'green'
        else return 'red'

      },
     uuidv4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
      },

      deleteImageItem(Document ,Index){
        
        Document.Images.splice(Index , 1)
        var context = this;
        return db.collection('Product')
                .doc(Document.ID)
                .update({"Images" : Document.Images})
                .then(_ => {  
                  
                  context.form.snackbar = true
                  context.form.snakMsg = "Delete Successfully"

                })
      },
      UpdateNewProductImage(Document , ImagePath){
        
          var storageRef = fb.storage().ref('ProductPic/' + Document.ID +'/'+ this.uuidv4())
          
          let UploadTask = storageRef.put(ImagePath);
          

          UploadTask.on('state_changed' , async function(snapshot){
          }, function(error) {

          },function() {
            
            UploadTask.snapshot.ref.getDownloadURL().then( function(downloadURL){
              
              //Document.Images.push(downloadURL)
                return db.collection('Product')
                .doc(Document.ID)
                .update({"Images" : Document.Images})
                .then(_ => {  
                  context.form.snakMsg = "Upload Successfully"
                  context.form.snackbar = true
                })
              
            })
          })
      },
      InsertItemToDB(){
       this.Finalitem.type = this.Finalitem.type.id
       this.Finalitem.D_ID = this.Finalitem.Distribution.D_ID
       var item  = this.Finalitem;
       var context = this; 
       db.collection('Product')
       .add( { ...item} )
       .then(docRef => {
        var Imagelist = new Array();
        var uuid = require("uuid");
        var x  = 0; var y = 0;
        context.docRefrance = docRef.id;
    
        
        for ( x = 0 ; x < context.form.Images.length ; x++){
          var storageRef = fb.storage().ref('ProductPic/' + docRef.id +'/'+ uuid.v4())
          let UploadTask = storageRef.put(context.ImageFire[x]);

          UploadTask.on('state_changed' , async function(snapshot){
          }, function(error) {

          },function() {
            
            UploadTask.snapshot.ref.getDownloadURL().then( function(downloadURL){
              console.log("Photo num " + x + " Uploaded done : " + downloadURL)
              Imagelist[y] = downloadURL
              y = y+1
              if (y == context.ImageFire.length)
              {
                
                return db.collection('Product')
                .doc(docRef.id)
                .update({"Images" : Imagelist , "ID" : docRef.id})
                .then(docRef  => {  
                  
                  var uuid1 = require("uuid");
                  var storageRef1 = fb.storage().ref('PrinterPDF/' +uuid1.v4())
                  let UploadTask1 = storageRef1.put(context.MainPDF);
                  UploadTask1.on('state_changed' , async function(snapshot){
                  }, function(error) {

                  },function() {
                  UploadTask1.snapshot.ref.getDownloadURL().then( function(downloadURL){
                
                return db.collection('Product')
                .doc(context.docRefrance)
                .update({"PDFUrl" : downloadURL })
                .then(_  => {  
                  
                  context.form.snackbar = true
                  context.selected = null
                  context.Finalitem.Price = 0.0
                  context.Finalitem.type =''
                  context.Finalitem.SalePrice = 0.0
                   context.Finalitem.Description = ''
                  context.Finalitem.ItemName  = ''
                  context.Finalitem.IsActive  = ''
                  context.Finalitem.D_ID = ''
                  context.Finalitem.Distribution = ''
                  context.form.Images = null
                  context.docRefrance = ''
                  context.MainPDF = ''
                })
                  })


                  })
                
                })
              }
            })
          })
        }

      })
     },
     rowClick(item, row)
     {
      var found = false;
      for(var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].ItemName == item.ItemName) {
          found = true;
          break;
        }
      }
      if (!found){
        var newitem =  Object.assign({}, item);
        this.Finalitem.Cost =  this.Finalitem.Cost + parseFloat(newitem.ItemCost) + parseFloat(newitem.AdditionalCost);
        this.selected.push(newitem);
      }else{
       alert("Item Already Used");
     }

   },
   filterOnlyCapsText (value, search, item) {
    return value != null &&
    search != null &&
    typeof value === 'string' &&
    value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
  },
  editItem (item) {
    this.editedIndex = this.Products.indexOf(item)
    this.editedItem = Object.assign({}, item)
    this.dialog = true
  },

  deleteItem (item) {
    this.editedIndex = this.Products.indexOf(item)
    this.editedItem = Object.assign({}, item)
    this.dialogDelete = true
  },
  deleteMainItem (item , index) {
    var context = this; 
    if (confirm('Are you sure you want to delete this ?')) {
     db.collection('Items').doc("MainItem").collection("Material").doc(item.id)
     .delete().then(_=>{
      alert("Item Was Deleted ...")
      context.MainItem.splice(index);
    })

   }
 },
 deleteItemConfirm () {
    
    db.collection('Product').doc(this.editedItem.ID)
      .delete().then(_=>{
      alert("Product Was Deleted ...")
    })
  this.closeDelete()
},

close () {
  this.dialog = false
  this.$nextTick(() => {
    this.editedItem = Object.assign({}, this.defaultItem)
    this.editedIndex = -1
  })
},

closeDelete () {
  this.dialogDelete = false
  this.$nextTick(() => {
    this.editedItem = Object.assign({}, this.defaultItem)
    this.editedIndex = -1
  })
},

save () {
  
  if (this.editedIndex > -1) {
    var context = this
    var itemid = this.editedItem.ID
      db.collection('Product').doc(this.editedItem.ID)
     .update(this.editedItem).then(_=>{
      
                        if (context.EditPDF){
                                            var uuid1 = require("uuid");
                  var storageRef1 = fb.storage().ref('PrinterPDF/' +uuid1.v4())
                  let UploadTask1 = storageRef1.put(context.EditPDF);
                  UploadTask1.on('state_changed' , async function(snapshot){
                  }, function(error) {

                  },function() {
                  UploadTask1.snapshot.ref.getDownloadURL().then( function(downloadURL){
                
                return db.collection('Product')
                .doc(itemid)
                .update({"PDFUrl" : downloadURL })
                .then(_  => {  
                  alert("Product Was Updated ...")
                })
                  })


                  })
                }else{
                  alert("Product Was Updated ...")
                }




    })
  } else {
    this.selected.push(this.editedItem)
  }
  this.close()


},

onPickFile(){
  this.$refs.fileinput.click();
},
onEditPickFile(){
  this.$refs.fileinput2.click();
},
ondelete(index){
  this.form.Images.splice( index, 1 );

},
onfilepicked(event){
  const files = event.target.files
  let filename = files[0].name
  if (filename.lastIndexOf('.') <= 0){
    return alert('please add a valid file !')
  }

  const reader = new FileReader()
  reader.addEventListener('load' , () => {
   this.form.Images.push(reader.result)
 })
  reader.readAsDataURL(files[0])
  this.ImageFire.push(files[0])
},

onEditfilepicked(event , document){
  
  const files = event.target.files
  let filename = files[0].name
  if (filename.lastIndexOf('.') <= 0){
    return alert('please add a valid file !')
  }


  const reader = new FileReader()
  reader.addEventListener('load' , () => {
 })
  reader.readAsDataURL(files[0])
  this.UpdateNewProductImage(document , files[0])
},

getRowItem() {
  db
  .collection('Items')
  .doc('RowItem')
  .collection('Material')
  .get()
  .then(snapshots => {
    if(snapshots.docs.length === 0) {return []}
      const exchange = snapshots.docs.map(doc => ( {...doc.data()}))
    this.setdesserts(exchange)

  })
},
getMainItem(){ 
  db
  .collection('Items')
  .doc('MainItem')
  .collection('Material')
  .get()
  .then(snapshots => {
    if(snapshots.docs.length === 0) {return []}
      const exchange = snapshots.docs.map(doc => ({...doc.data(), id: doc.id , components : doc.data().components}))

    this.setMainItem(exchange)

  })
},
getTypeofItem(){
  db
  .collection('Categories')
  .get()
  .then(snapshots => {

    if(snapshots.docs.length === 0) {return []}
      const Types = snapshots.docs.map(doc => ({...doc.data(), id: doc.id , components : doc.data().components}))

    this.setTypeofItem(Types)

  })
},
getcategoryName : function(CategoriesID,index){
  return db.collection('Categories')
  .doc(CategoriesID)
  .get()
  .then(async snapshot => {
    let x = snapshot.data().C_Name
    document.getElementById(CategoriesID + index).innerHTML = x


    //return document.SupplierName;

  })
},
set_component(components){
  this.components = components
},
setdesserts(desserts){

  this.desserts = desserts
},
setMainItem(MainItem){
  this.MainItem = MainItem
},
setTypeofItem(Type){
  this.Type = Type
},
setDetailsItem(ItemDetails){

  this.ItemDetails = ItemDetails
},
},
created(){
  this.$store.dispatch('bindAllDistribution','null')
  this.$store.dispatch('bindProduct')
  this.getRowItem()
  this.getMainItem()
  this.getTypeofItem()
}
}
</script>


<style lang="scss">
.tim-note {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  width: 260px;
}
</style>
